import { Spin, theme } from 'antd';
import React from 'react';
import { styled } from 'styled-components';

interface FullscreenSpinnerProps {
  isLoading?: boolean;
}

interface OverlayProps {
  $bgColor: string;
}

const Overlay = styled.div<OverlayProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: ${({ $bgColor }) => $bgColor};
  z-index: 1000;
`;

export const FullscreenSpinner = ({
  isLoading = true,
}: FullscreenSpinnerProps) => {
  const {
    token: { colorTextQuaternary },
  } = theme.useToken();

  if (!isLoading) {
    return null;
  }
  return (
    <Overlay $bgColor={colorTextQuaternary}>
      <Spin size="large" />
    </Overlay>
  );
};
