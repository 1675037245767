import { createApi } from '@reduxjs/toolkit/query/react';
import { createApiBaseQuery } from '../../services';
import { Project } from '../../types';
import { URLProjectsEndpoints } from '../../constant/URLEndpoints';
import { PROJECTS } from '../../constant/apiTags';

// Define a service using a base URL and expected endpoints
export const projectsApi = createApi({
  reducerPath: 'projectsApi',
  baseQuery: createApiBaseQuery(),
  tagTypes: [PROJECTS],
  endpoints: (builder) => ({
    getProjects: builder.query<
      Project[],
      {
        name?: string;
        jobTitle?: string;
        categoryName?: string;
      }
    >({
      query: (params) => {
        return {
          url: URLProjectsEndpoints.PROJECTS,
          method: 'GET',
          params,
        };
      },
      providesTags: [PROJECTS],
    }),
    getProject: builder.query<
      Project,
      {
        id: string;
      }
    >({
      query: (params) => {
        return {
          url: URLProjectsEndpoints.PROJECTS + `/${params.id}`,
          method: 'GET',
        };
      },
      providesTags: [PROJECTS],
    }),
    editProject: builder.mutation<Project, Project>({
      query: (params) => {
        return {
          url: URLProjectsEndpoints.PROJECTS + `/${params.id}`,
          method: 'PATCH',
          body: params,
        };
      },
      invalidatesTags: [PROJECTS],
    }),
    deleteProject: builder.mutation<void, { id: string }>({
      query: (params) => {
        return {
          url: URLProjectsEndpoints.PROJECTS + `/${params.id}`,
          method: 'DELETE',
          body: params,
        };
      },
      invalidatesTags: [PROJECTS],
    }),
    addProject: builder.mutation<Project, Project>({
      query: (params) => {
        return {
          url: URLProjectsEndpoints.PROJECTS,
          method: 'POST',
          body: params,
        };
      },
      invalidatesTags: [PROJECTS],
    }),
  }),
});

export const {
  useLazyGetProjectsQuery,
  useGetProjectQuery,
  useDeleteProjectMutation,
  useAddProjectMutation,
  useEditProjectMutation,
} = projectsApi;
