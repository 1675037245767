import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TemplateItem } from '../../types';

interface ResumeState {
  templates: TemplateItem[];
}

const initialState: ResumeState = {
  templates: [],
};

const templateSlice = createSlice({
  name: 'template',
  initialState,
  reducers: {
    setTemplates: (state, action: PayloadAction<TemplateItem[]>) => {
      state.templates = action.payload;
    },
  },
});

export const templateReducer = templateSlice.reducer;
export const { setTemplates } = templateSlice.actions;
