import React, { PropsWithChildren, useEffect } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../store';
import { AppRoute } from '../constant/AppRoute';

const blockedQueries = ['/', '/' + AppRoute.LOGIN, AppRoute.ROOT];

export const RedirectAuthorizedRoute = ({ children }: PropsWithChildren) => {
  const { user, redirectAfterLogin } = useAppSelector((state) => state.auth);
  const [params, setParams] = useSearchParams();

  useEffect(() => {
    if (!blockedQueries.includes(redirectAfterLogin)) {
      setParams({ redirectAfterLogin });
    }
  }, [params, redirectAfterLogin, setParams]);

  return user ? (
    <Navigate
      to={params.get('redirectAfterLogin') ?? '/' + AppRoute.ROOT}
      replace
    />
  ) : (
    children
  );
};
