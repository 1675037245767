import { URLResumeEndpoints } from '../constant/URLEndpoints';
import {
  ChangeTemplateParams,
  PagingParams,
  ResumeAssignTeamParams,
  ResumeDataRequest,
  ResumeItem,
  ResumeParams,
  ResumePhotoParams,
  ResumeResponse,
  ResumeTemplateParams,
  SaveResumeParams,
} from '../types';
import { apiClient } from './apiClient.config';
import { ChangeHeightRequest } from '../types/service';

export const saveResume = async ({ templateId }: SaveResumeParams) => {
  const response = await apiClient.post<ResumeItem>(
    URLResumeEndpoints.RESUMES,
    {
      templateId,
    },
  );
  return response.data;
};

export const getResumes = async ({ pageNumber, pageSize }: PagingParams) => {
  const response = await apiClient.get<ResumeResponse>(
    URLResumeEndpoints.RESUMES,
    {
      params: {
        pageNumber,
        pageSize,
      },
    },
  );
  return response.data;
};

export const getResume = async ({ resumeId }: ResumeParams) => {
  const response = await apiClient.get<ResumeItem>(
    `${URLResumeEndpoints.RESUMES}/${resumeId}`,
    {
      params: {
        resumeId,
      },
    },
  );
  return response.data;
};

export const deleteResume = async ({ resumeId }: ResumeParams) => {
  const response = await apiClient.delete(
    `${URLResumeEndpoints.RESUMES}/${resumeId}`,
  );
  return response.data;
};

export const updateResume = async ({
  id,
  ...restParams
}: ResumeDataRequest) => {
  const response = await apiClient.patch<ResumeItem>(
    `${URLResumeEndpoints.RESUMES}/${id}`,
    restParams,
  );

  return response.data;
};

export const changePreviewHeight = async ({
  id,
  height,
}: ChangeHeightRequest) => {
  const response = await apiClient.patch<string>(
    `${URLResumeEndpoints.RESUMES}/${id}/height?previewHeight=${height}`,
  );

  return response.data;
};

export const updateResumePhoto = async ({
  resumeId,
  image,
  onUploadProgress,
}: ResumePhotoParams) => {
  const response = await apiClient.patch(
    `${URLResumeEndpoints.RESUMES}/${resumeId}/photo`,
    image,
    {
      onUploadProgress,
    },
  );

  return response.data;
};

export const deleteResumePhoto = async ({ resumeId }: ResumeParams) => {
  const response = await apiClient.delete(
    `${URLResumeEndpoints.RESUMES}/${resumeId}/photo`,
  );
  return response.data;
};

export const getPreviewHTML = async ({ resumeId }: ResumeParams) => {
  const response = await apiClient.get<string>(
    `${URLResumeEndpoints.RESUMES}/${resumeId}/html`,
  );

  return response.data;
};

export const getResumePdfLink = async ({ resumeId }: ResumeParams) => {
  const response = await apiClient.get<string>(
    `${URLResumeEndpoints.RESUMES}/${resumeId}/pdf`,
  );
  return response.data;
};

export const getResumeDocxLink = async ({ resumeId }: ResumeParams) => {
  const response = await apiClient.get<string>(
    `${URLResumeEndpoints.RESUMES}/${resumeId}/docx`,
  );
  return response.data;
};

export const uploadPreviewAndPublishResume = async ({
  resumeId,
  image,
  onUploadProgress,
}: ResumePhotoParams) => {
  const response = await apiClient.patch<ResumeItem>(
    `${URLResumeEndpoints.RESUMES}/${resumeId}/preview`,
    image,
    { onUploadProgress },
  );

  return response.data;
};

export const removeResumeTeam = async ({ resumeId }: ResumeParams) => {
  const response = await apiClient.patch(
    `${URLResumeEndpoints.RESUMES}/${resumeId}/removeTeam`,
  );
  return response.data;
};

export const assignResumeTeam = async ({
  resumeId,
  name,
}: ResumeAssignTeamParams) => {
  const response = await apiClient.patch<ResumeItem>(
    `${URLResumeEndpoints.RESUMES}/${resumeId}/assignTeam`,
    {
      teamName: name,
    },
  );
  return response.data;
};

export const changeThemeColor = async ({
  resumeId,
  themeColorNumber,
}: ResumeTemplateParams) => {
  const response = await apiClient.patch<ResumeItem>(
    `${URLResumeEndpoints.RESUMES}/${resumeId}/template/color`,
    {
      themeColorNumber: themeColorNumber,
    },
  );
  return response.data;
};

export const changeTemplate = async ({
  resumeId,
  templateId,
}: ChangeTemplateParams) => {
  const response = await apiClient.patch<ResumeItem>(
    `${URLResumeEndpoints.RESUMES}/${resumeId}/template`,
    {
      templateId,
    },
  );

  return response.data;
};

export const duplicateResume = async ({ resumeId }: ResumeParams) => {
  const response = await apiClient.post<ResumeItem>(
    `${URLResumeEndpoints.RESUMES}/${resumeId}/duplication`,
  );
  return response.data;
};
