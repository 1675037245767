import { ThemeConfig } from 'antd';

const theme: ThemeConfig = {
  token: {
    colorPrimary: '#133e6c',
    fontSize: 14,
    fontFamily: 'Roboto, "Segoe UI", Ubuntu, sans-serif',
    borderRadius: 8,
    colorBorder: '#CFD9E0',
    colorPrimaryBg: '#DFE8ED',
    colorBgContainer: '#F0F2F6',
  },
  components: {
    Button: {
      paddingContentHorizontal: 15,
      colorPrimaryBorder: '#607d91',
      controlOutline: 'rgba(0, 0, 0, 0.04)',
      colorBgContainer: '#ffffff',
    },
    Collapse: {
      colorBgContainer: '#F0F2F6',
      colorFillAlter: '#fff',
    },
    Input: {
      colorBgContainer: '#ffffff',
    },
    InputNumber: {
      colorBgContainer: '#ffffff',
    },
    Upload: {
      colorFillAlter: '#ffffff',
    },
    DatePicker: {
      colorBgContainer: '#ffffff',
    },
    Select: {
      colorBgContainer: '#ffffff',
    },
    Table: {
      colorBgContainer: '#FFFFFF',
    },
  },
};

export default theme;
