import { URLTemplatesEndpoints } from '../constant/URLEndpoints';
import { TemplateItem, TemplateParams, TemplateResponse } from '../types';
import { apiClient } from './apiClient.config';

export const getTemplates = async ({
  pageNumber,
  pageSize,
}: Omit<TemplateParams, 'templateId'>) => {
  const response = await apiClient.get<TemplateResponse>(
    URLTemplatesEndpoints.TEMPLATES,
    {
      params: {
        pageNumber,
        pageSize,
      },
    },
  );
  return response.data;
};

export const getTemplateById = async ({
  templateId,
}: Pick<TemplateParams, 'templateId'>) => {
  const response = await apiClient.get<TemplateItem>(
    `${URLTemplatesEndpoints.TEMPLATES}/${templateId}`,
  );
  return response.data;
};
