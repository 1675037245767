import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store';
import './index.css';
import theme from './theme';
import { ConfigProvider } from 'antd';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import 'dayjs/locale/ru';
import locale from 'antd/locale/ru_RU';
import { Root } from './Root';
import { PrivateRoute, RedirectAuthorizedRoute } from './guardRoutes';
import { AppRoute } from './constant/AppRoute';

const Main = lazy(() => import('./pages/Main'));
const Login = lazy(() => import('./pages/Login'));
const Editor = lazy(() => import('./pages/Editor'));

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!;
const root = createRoot(container);

const router = createBrowserRouter([
  {
    path: AppRoute.ROOT,
    element: <Root />,
    children: [
      {
        index: true,
        element: (
          <PrivateRoute>
            <Main />
          </PrivateRoute>
        ),
      },
      {
        path: AppRoute.LOGIN,
        element: (
          <RedirectAuthorizedRoute>
            <Login />
          </RedirectAuthorizedRoute>
        ),
      },
      {
        path: AppRoute.EDITOR + '/:resumeId',
        element: (
          <PrivateRoute>
            <Editor />
          </PrivateRoute>
        ),
      },
    ],
  },
]);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider theme={theme} locale={locale}>
        <RouterProvider router={router} />
      </ConfigProvider>
    </Provider>
  </React.StrictMode>,
);
