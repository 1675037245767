import Cookies from 'js-cookie';
import { TokenSet, UserLogin, UserResponse } from '../types';
import { apiClient } from './apiClient.config';
import { URLAuthEndpoints } from '../constant/URLEndpoints';
import { Token } from '../constant/Tokens';

export const login = async ({ username, password }: UserLogin) => {
  const response = await apiClient.post<TokenSet>(URLAuthEndpoints.LOGIN, {
    username,
    password,
  });
  Cookies.set(Token.ACCESS, response.data.accessToken);
  Cookies.set(Token.REFRESH, response.data.refreshToken);
};

export const refreshToken = async () => {
  const response = await apiClient.post<TokenSet>(URLAuthEndpoints.REFRESH, {
    refreshToken: Cookies.get(Token.REFRESH),
  });
  Cookies.set(Token.ACCESS, response.data.accessToken);
  Cookies.set(Token.REFRESH, response.data.refreshToken);
};

export const getAuthUser = async () => {
  const response = await apiClient.get<UserResponse>(URLAuthEndpoints.USER);
  return response.data;
};
