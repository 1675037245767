import { createApi } from '@reduxjs/toolkit/query/react';
import { createApiBaseQuery } from '../../services';
import { URLCategories, URLJobsEndpoints } from '../../constant/URLEndpoints';
import { JobsResponse, CategoriesResponse } from '../../types';

// Define a service using a base URL and expected endpoints
export const dictionaryApi = createApi({
  reducerPath: 'dictionaryApi',
  baseQuery: createApiBaseQuery(),
  endpoints: (builder) => ({
    getPositions: builder.query<{ value: string; label: string }[], void>({
      query: () => ({ url: URLJobsEndpoints.JOBS }),
      transformResponse: (response: JobsResponse[]) =>
        response.map((item) => ({ value: item.id, label: item.title })),
    }),
    getCategories: builder.query<{ value: string; label: string }[], void>({
      query: () => {
        return {
          url: URLCategories.categories,
        };
      },
      transformResponse: (response: CategoriesResponse[]) =>
        response.map((item) => ({ value: item.name, label: item.name })),
    }),
  }),
});

export const { useGetPositionsQuery, useGetCategoriesQuery } = dictionaryApi;
