import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ResumeItem, Role } from '../../types';

interface SavingType {
  isLoading: boolean;
  isError: boolean;
}

interface ResumeState {
  roles: Role[] | null;
  saving: SavingType;
  previewHTML: string;
  previewPageCount: number;
  previewHeight: number;
  resume: ResumeItem | null;
}

const initialState: ResumeState = {
  roles: null,
  saving: {
    isLoading: false,
    isError: false,
  },
  previewHTML: '',
  previewPageCount: 1,
  previewHeight: 1122,
  resume: null,
};

const resumeSlice = createSlice({
  name: 'resume',
  initialState,
  reducers: {
    setRoles: (state, action: PayloadAction<Role[]>) => {
      state.roles = action.payload;
    },

    setSavingLoading: (state, action: PayloadAction<boolean>) => {
      state.saving.isLoading = action.payload;
    },

    setSavingError: (state, action: PayloadAction<boolean>) => {
      state.saving.isError = action.payload;
    },

    setPreviewHTML: (state, action: PayloadAction<string>) => {
      state.previewHTML = action.payload;
    },

    setResume: (state, action: PayloadAction<ResumeItem>) => {
      state.resume = action.payload;
    },

    setPreviewPageCount: (
      state,
      action: PayloadAction<{
        previewPageCount: number;
        previewHeight: number;
      }>,
    ) => {
      state.previewPageCount = action.payload.previewPageCount;
      state.previewHeight = action.payload.previewHeight;
    },
  },
});

export const resumeReducer = resumeSlice.reducer;
export const {
  setRoles,
  setSavingLoading,
  setSavingError,
  setPreviewHTML,
  setResume,
  setPreviewPageCount,
} = resumeSlice.actions;
