import { URLTeamEndpoints } from '../constant/URLEndpoints';
import { Team } from '../types';
import { apiClient } from './apiClient.config';

export const findAllTeams = async () => {
  const response = await apiClient.get<Team[]>(URLTeamEndpoints.TEAMS);
  return response.data;
};

export const saveTeam = async ({ name }: Pick<Team, 'name'>) => {
  const response = await apiClient.post<Team>(URLTeamEndpoints.TEAMS, {
    name,
  });
  return response.data;
};

export const deleteTeam = async ({ id }: Pick<Team, 'id'>) => {
  const response = await apiClient.delete(`${URLTeamEndpoints.TEAMS}/${id}`);
  return response.data;
};
