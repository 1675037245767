import axios, { AxiosError, AxiosRequestConfig, Method } from 'axios';
import Cookies from 'js-cookie';
import { refreshToken } from './authService';
import { Token } from '../constant/Tokens';
import { URLAuthEndpoints } from '../constant/URLEndpoints';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { ErrorResponse } from '../types';

export type MaybeArray<T> = T | T[];

interface AxiosBaseQueryArgs {
  method?: Method;
  url: string;
  body?: unknown;
  params?: Record<string, MaybeArray<string | number | undefined | null>>;
  headers?: AxiosRequestConfig['headers'];
}

interface AxiosBaseQueryError {
  status?: number;
  data?: ErrorResponse;
}

export const apiClient = axios.create({
  baseURL: '/api/v1',
  withCredentials: false,
});

apiClient.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${Cookies.get(Token.ACCESS)}`;
  return config;
});

// Переменная для записи активного выполнения запроса на обновление токена
let refreshPromise: Promise<void> | undefined;

apiClient.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    // Запоминаем конфиг оригинального запроса
    const originalRequest = error.config;

    // С помощью `!originalRequest._isRetry` проверяем что это не повторная попытка отправить запрос, т.к. иначе попадём в рекурсию при 401 ошибке
    if (error?.response?.status === 401 && !originalRequest._isRetry) {
      // Проверяем что запрос с 401 ошибкой не является запросом на refresh или login
      const isMatchExcludedURLs =
        error.config.url === URLAuthEndpoints.REFRESH ||
        error.config.url === URLAuthEndpoints.LOGIN;

      if (isMatchExcludedURLs || !Cookies.get(Token.REFRESH)) {
        Cookies.remove(Token.ACCESS);
        Cookies.remove(Token.REFRESH);
        throw error;
      }

      // Если переменная не заполнена, то записываем в неё Promise на выполнение рефреша токена
      if (!refreshPromise) {
        refreshPromise = refreshToken();
      }

      // Помечаем исходный запрос как "повторный", чтобы если при повторном запросе снова прилетит 401 мы не ушли в рекурсию
      originalRequest._isRetry = true;

      // Дожидаемся выполнения запроса на обновление токена
      await refreshPromise;

      // обнуляем значение переменной
      refreshPromise = undefined;

      // отправляем повторно исходный запрос, в котором есть указание, что он повторный (_isRetry = true)
      return apiClient.request(originalRequest);
    }

    // Если ошибка при запросе не 401, то пробрасываем ошибку дальше
    return Promise.reject(error);
  },
);

export const createApiBaseQuery =
  (
    basePath = '',
  ): BaseQueryFn<AxiosBaseQueryArgs, unknown, AxiosBaseQueryError> =>
  async ({ url, body, method, params, headers }, { signal, type }) => {
    const requestUrl = [basePath, url]
      .map((path) => path.replace(/^\\+|\\+$/g, ''))
      .join('');
    try {
      const result = await apiClient.request({
        url: requestUrl,
        method: method ?? (type === 'mutation' ? 'post' : 'get'),
        data: body,
        headers: {
          ...headers,
        },
        params,
        signal,
      });

      return result;
    } catch (axiosError) {
      const err = axiosError as AxiosError;

      return {
        error: err.response?.data || err.message,
      };
    }
  };
