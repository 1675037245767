import React, { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../store';
import { AppRoute } from '../constant/AppRoute';

export const PrivateRoute = ({ children }: PropsWithChildren) => {
  const { user } = useAppSelector((state) => state.auth);

  return user ? children : <Navigate to={'/' + AppRoute.LOGIN} replace />;
};
