import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { UserResponse } from '../../types';
import { Token } from '../../constant/Tokens';

export interface AuthState {
  user: UserResponse | null;
  redirectAfterLogin: string;
}

const initialState: AuthState = {
  user: null,
  redirectAfterLogin: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserResponse>) => {
      state.user = action.payload;
    },
    setRedirectAfterLogin: (state, action: PayloadAction<string>) => {
      state.redirectAfterLogin = action.payload;
    },
    logout: (state) => {
      Cookies.remove(Token.ACCESS);
      Cookies.remove(Token.REFRESH);
      state.user = null;
      state.redirectAfterLogin = '';
    },
  },
});

export const authReducer = authSlice.reducer;
export const { setUser, setRedirectAfterLogin, logout } = authSlice.actions;
