import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { authReducer, resumeReducer, templateReducer } from './reducers';
import { dictionaryApi } from './api/dictionaryApi';
import { projectsApi } from './api/projectsApi';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    resume: resumeReducer,
    template: templateReducer,
    [dictionaryApi.reducerPath]: dictionaryApi.reducer,
    [projectsApi.reducerPath]: projectsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      dictionaryApi.middleware,
      projectsApi.middleware,
    ),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
